<template>
  <div class="emoji-Bigbox">
    <div class="emoji-box-cont">
        <div
            class="emoji-box"
            v-for="(item, index) in emoji_icon"
            :key="index"
            @click="pickEmoji(index)"
        >
            {{ item }}
        </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      emoji_icon: [
        "😀",
        "😃",
        "😄",
        "😁",
        "😆",
        "😅",
        "🤣",
        "😂",
        "🙂",
        "🙃",
        "😉",
        "😊",
        "😇",
        "😍",
        "🤩",
        "😘",
        "😗",
        "😚",
        "😙",
        "😋",
        "😛",
        "😜",
        "🤪",
        "😝",
        "🤑",
        "🤗",
        "🤭",
        "🤫",
        "🤔",
        "🤐",
        "🤨",
        "😐",
        "😑",
        "😶",
        "😏",
        "😒",
        "🙄",
        "😬",
        "🤥",
        "😌",
        "😔",
        "😪",
        "🤤",
        "😴",
        "😷",
        "🤒",
        "🤕",
        "🤢",
        "🤮",
        "🤧",
        "😵",
        "🤯",
        "🤠",
        "😎",
        "🤓",
        "🧐",
        "😕",
        "😟",
        "🙁",
        "😮",
        "😯",
        "😲",
        "😳",
        "😦",
        "😧",
        "😨",
        "😰",
        "😥",
        "😢",
        "😭",
        "😱",
        "😖",
        "😣",
        "😞",
        "😓",
        "😩",
        "😫",
        "😤",
        "😡",
        "😠",
        "🤬",
        "😈",
        "👿",
        "💀",
        "💩",
        "🤡",
        "👹",
        "👺",
        "👻",
        "👽",
        "👾",
        "🤖",
        "😺",
        "😸",
        "😹",
        "😻",
        "😼",
        "😽",
        "🙀",
        "😿",
        "😾",
        "💋",
        "👋",
        "🤚",
        "🖐",
        "✋",
        "🖖",
        "👌",
        "🤞",
        "🤟",
        "🤘",
        "🤙",
        "👈",
        "👉",
        "👆",
        "🖕",
        "👇",
        "👍",
        "👎",
        "✊",
        "👊",
        "🤛",
        "🤜",
        "👏",
        "🙌",
        "👐",
        "🤲",
        "🤝",
        "🙏",
        "💅",
        "🤳",
        "💪",
        "👂",
        "👃",
        "🧠",
        "👀",
        "👁",
        "👅",
        "👄",
        "👶",
        "🧒",
        "👦",
        "👧",
        "🧑",
        "👱",
        "👨",
        "🧔",
        "👱‍",
        "👨‍",
        "👨‍",
        "👨‍",
        "👨‍",
        "👩",
        "👱‍",
        "👩‍",
        "👩‍",
        "👩‍",
        "👩‍",
        "🧓",
        "👴",
        "👵",
        "💏",
        "👨‍",
        "👩‍",
        "💑",
        "👨‍",
        "👩‍",
        "🗣",
        "👤",
        "👥",
        "👣",
        "🌂",
        "☂",
        "👓",
        "🕶",
        "👔",
        "👕",
        "👖",
        "🧣",
        "🧤",
        "🧥",
        "🧦",
        "👗",
        "👘",
        "👙",
        "👚",
        "👛",
        "👜",
        "👝",
        "🎒",
        "👞",
        "👟",
        "👠",
        "👡",
        "👢",
        "👑",
        "👒",
        "🎩",
        "🎓",
        "🧢",
        "⛑",
        "💄",
        "💍",
        "💼"
      ]
    }
  },
  methods: {
    pickEmoji(idx) {
      return this.$emit("selectEmoji", this.emoji_icon[idx])
    },
  }
}
</script>
<style lang="scss" scoped>

.emoji-Bigbox {
  width: 287px;
  height: 300px;
  background: #4e4f5d;
  border-radius: 4px;
  padding: 10px 6px 10px 10px;
  position: relative;
  .emoji-box-cont{
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .emoji-box {
    display: inline-block;
    width: 30px;
    height: 30px;
    font-size: 22px;
    // border: 1px solid red;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      border-radius: 5px;
      background: rgba(160, 158, 158, 0.39);
      transform: scale(1.1);
    }
  }
}
.emoji-Bigbox:after{
    content: "";
    width: 0;
    height: 0;
    left: 9px;
    bottom: -10px;
    border-left: solid 10px transparent;
    border-top: solid 10px #4e4f5d;
    border-right: solid 10px transparent;
    position: absolute;
}
</style>